
	frappe.templates['item_dashboard_list'] = `{% for d in data %}
    <div class="dashboard-list-item">
        <div class="row" parent="{{ d.item_code }}">
            <div class="col-sm-2 small" style="margin-top: 8px;">
                <a data-type="warehouse" data-name="{{ d.warehouse }}">{{ d.warehouse }}</a>
            </div>
            <div class="col-sm-1 small" style="margin-top: 8px;">
                <a data-type="company" data-name="{{ d.target_site }}">{{ d.target_site }}</a>
            </div>
            <div class="col-sm-2" style="margin-top: 8px;">
                {% if show_item %}
                    <a data-type="item" data-name="{{ d.item_code }}">{{ d.item_code }}
                        {% if d.item_name != d.item_code %}({{ d.item_name }}){% endif %}
                    </a>
                {% endif %}
            </div>
            <div class="col-sm-3 small">
                <span class="inline-graph">
                    <span class="inline-graph-half" title="{{ __(" Reserved Qty") }}">
                        <span class="inline-graph-count">{{"Backordered: "+ d.total_reserved }}</span>
                        <span class="inline-graph-bar">
                            <span class="inline-graph-bar-inner"
                                style="width: {{ cint(Math.abs(d.total_reserved)/max_count * 100) || 5 }}%">
                            </span>
                        </span>
                    </span>
                    <span class="inline-graph-half" title="{{ __(" Actual Qty {0} / Waiting Qty {1}", [d.actual_qty, d.pending_qty]) }}">
                        <span class="inline-graph-count" style="white-space: nowrap;">
                            {{"Actual: " + d.actual_qty }} {{ (d.pending_qty > 0) ? ("| Pending: " + d.pending_qty) : "" }}
                        </span>
                        <span class="inline-graph-bar">
                            <span class="inline-graph-bar-inner dark"
                                style="width: {{ cint(d.actual_qty/max_count * 100) }}%">
                            </span>
                            {% if d.pending_qty > 0 %}
                                <span class="inline-graph-bar-inner" title="{{ __(" Projected Qty") }}"
                                    style="width: {{ cint(d.pending_qty/max_count * 100) }}%">
                                </span>
                            {% endif %}
                        </span>
                    </span>
                </span>
            </div>
            {% if d.current_site == d.target_site %}
                <div class="col-sm-1 small" style="margin: 10px auto">
                    <a class="fas fa-chevron-circle-down pending-breakdown" data-item="{{ d.item_code }}"
                        data-warehouse="{{ d.warehouse }}">
                    </a>
                    <a class="fas fa-chevron-circle-up to-collapse" data-item="{{ d.item_code }}"
                        data-warehouse="{{ d.warehouse }}">
                    </a>
                </div>
            {% else %}
                <div class="col-sm-1 small" style="margin: 10px auto"></div>
            {% endif %}
            {% if can_write %}
                {% if d.current_site == d.target_site %}
                    <div class="col-sm-2 text-right" style="margin: var(--margin-sm) 0;">
                        {% if d.actual_qty %}
                            <button class="btn btn-default btn-xs btn-move" 
                                    data-disable_quick_entry="{{ d.disable_quick_entry }}"
                                    data-warehouse="{{ d.warehouse }}" 
                                    data-actual_qty="{{ d.actual_qty }}"
                                    data-item="{{ escape(d.item_code) }}">
                                {{ __("Move") }}
                            </button>
                        {% endif %}
                        <button style="margin-left: 7px;" class="btn btn-default btn-xs btn-add"
                                data-disable_quick_entry="{{ d.disable_quick_entry }}" 
                                data-warehouse="{{ d.warehouse }}"
                                data-actual_qty="{{ d.actual_qty }}" 
                                data-item="{{ escape(d.item_code) }}"
                                data-rate="{{ d.valuation_rate }}">
                            {{ __("Add") }}
                        </button>
                    </div>
                {% else %}
                    {% if d.show_stock_buttons %}
                        <div class="col-sm-3 text-right" style="margin: var(--margin-sm) 0;">
                            {% if d.batch_match %}
                                <button class="btn btn-default btn-xs btn-send-stock" 
                                        data-has_batch_no="{{ d.has_batch_no }}"
                                        data-warehouse="{{ d.warehouse }}" 
                                        data-actual_qty="{{ d.actual_qty }}" 
                                        data-current_site="{{ d.current_site }}"
                                        data-target_site="{{ d.target_site }}" 
                                        data-item="{{ escape(d.item_code) }}" 
                                        data-current_site_qty="{{ d.current_site_qty }}">
                                    {{ __("Transfer From") }}
                                </button>
                                <button class="btn btn-default btn-xs btn-transfer" 
                                        data-has_batch_no="{{ d.has_batch_no }}"
                                        data-warehouse="{{ d.warehouse }}" 
                                        data-actual_qty="{{ d.actual_qty }}" 
                                        data-current_site="{{ d.current_site }}"
                                        data-target_site="{{ d.target_site }}" 
                                        data-item="{{ escape(d.item_code) }}" 
                                        data-current_site_qty="{{ d.current_site_qty }}">
                                    {{ __("Transfer To") }}
                                </button>
                            {% else %}
                                <div class="col-sm-13 text-right" style="margin: var(--margin-sm) 0;">Transfer Disabled. <b>Has Batch No</b> Flag Mismatch.</div>
                            {% endif %}
                        </div>
                    {% else %}
                        <div class="col-sm-3 text-right" style="margin: var(--margin-sm) 0;"></div>
                    {% endif %}
                {% endif %}
            {% endif %}
        </div>
    </div>
{% endfor %}
`;
